@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --sidebar-width: 290px;
  --navbar-height: 60px;
  --bottomnav-height: 80px;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.section-2-green-effect {
  filter: drop-shadow(0px 4.461px 20px #54c3af);
}

.section-2-red-effect {
  filter: drop-shadow(0px 3.943px 29.076px #ed837f);
}

.section-3-image-effect {
  transform: rotate(-11.933deg);
  border-radius: 132.568px;
  border: 1.326px solid #24bfa3;
  box-shadow: 0px 4px 4px 0px #5bcfba;
}

.hide-scrollbars {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.hide-scrollbars::-webkit-scrollbar {
  display: none;
}

.noselect,
br {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dragdrop {
  outline: none !important;
}

.chakra-button__icon {
  margin-inline-start: 0.2rem !important;
  margin-inline-end: 0.3rem !important;
}

.chakra-checkbox__control[data-checked],.chakra-radio__control[data-checked] {
  background: #24bfa3!important;
  border-color: #24bfa3!important;
}

@media (max-width: 750px) {
  .chakra-popover__popper {
    display: flex;
    justify-content: center;
    width: 100vw;
  }

  .chakra-button__icon {
    margin-inline-start: 0.1rem !important;
    margin-inline-end: 0.3rem !important;
  }

  .rdrDefinedRangesWrapper {
    display: none;
  }
}
.progressbar div {
  background-color: #ed837f !important;
}

#react-select-languages-listbox,
#react-select-categories-listbox {
  z-index: 1000;
}

.noBorder:focus-visible {
  box-shadow: none !important;
}

.homeBackground {
  background-image: url('../public/home/greenGradient.svg');
  background-color: #ffffff;
  position: fixed;
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0px;
  display: block;
}

.bgBlurNav {
  backdrop-filter: blur(5px);
  background: #fff9;
}

#homeSearch .searchCont {
  max-width: 615px;
}

#homeSearch .searchCont input {
  height: 50px;
  padding-left: 12px;
  font-size: 18px;
}

#homeSearch .searchCont ::placeholder {
  font-size: 18px;
  font-weight: 500;
}

#homeSearch .searchCont svg {
  width: 31px;
  height: 31px;
  margin-right: 10px;
}

#homeSearch .joinOthers {
  width: 345px;
  padding: 10px;
  background: #24bfa30f;
  border: 1px solid #24bfa3;
  border-radius: 40px;
}

.joinOthers li span {
  margin-left: -12px;
}

.intrestLangBtn {
  width: auto;
  padding: 12px 20px;
  margin: 8px;
  border: 1.27px solid #24bfa3;
  border-radius: 40px;
  cursor: pointer;
}

#homeSearch .searchContMob {
  margin: 0px;
}

#homeSearch .searchContMob input {
  height: 40px;
  padding-left: 6px;
  font-size: 14px;
}

#homeSearch .searchContMob ::placeholder {
  font-size: 14px;
  font-weight: 500;
}

#homeSearch .searchContMob svg {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.closeButton {
  margin: 0px;
}

.closeButton:focus-visible {
  box-shadow: none !important;
}

#homeSearch .intrestLangBtn svg {
  margin-left: 7px;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none !important;
}

input[type='search'] {
  background-color: #fff !important;
}

.coloredbox .chakra-checkbox__control {
  border-color: #24bfa3;
  border-radius: 3px;
}

.coloredbox .chakra-checkbox__control[data-checked] {
  background-color: #24bfa3;
  border-color: #24bfa3;
}

.coloredbox .chakra-checkbox__control[data-checked] .chakra-checkbox__icon {
  color: white;
}

.progress-bar-right {
  transform: rotateY(180deg);
}

.progress-bar-right .chakra-progress__indicator {
  transform: rotateY(180deg);
}

/* uppy file uploader */
.uppy-Dashboard-AddFiles-title {
  text-align: center;
  opacity: 0.7;
  font-size: 24px !important;
  font-weight: 500 !important;
  color: #3e3e3e !important;
  font-family: __Plus_Jakarta_Sans_e3c363, __Plus_Jakarta_Sans_Fallback_e3c363,
    ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
}

.uppy-Dashboard-inner {
  background-color: transparent !important;
  border: none !important;
}

.chakra-tabs__tab[aria-selected='true'],
.chakra-tabs__tab[data-selected] {
  --tabs-color: #24bfa3 !important;
}

.chakra-tabs__tab[aria-selected='false'] {
  --tabs-color: #9f9f9f !important;
}

/* adSense */
.homeAd {
  text-align: center;
  margin: 20px 15px;
}

.categoryAd {
  margin: 20px auto;
  text-align: center;
  grid-column: 1 / -1;
  grid-row: 2;
}

#hoverPlayer .vjs-control-bar {
  display: none !important;
}

#hoverPlayer .vjs-loading-spinner {
  display: none !important;
}
/* larger than tablets */
@media only screen and (min-width: 768px) {
  .hoverCategory:hover {
    background: #ed837f;
    color: #fff;
  }
}
/* till tablet */
@media only screen and (max-width: 768px) {
  .hoverCategory:active {
    background: #ed837f;
    color: #fff;
  }
}

/* large mobile */
@media only screen and (max-width: 600px) {
  #homeSearch {
    margin: 24px 16px 0px 16px;
  }

  #homeSearch p {
    font-size: 16px;
    text-align: left;
  }

  #homeSearch .joinOthers {
    display: none;
  }

  #homeSearch .intrestLangBtn {
    width: 100%;
    padding: 11.5px 20px;
    margin: 6px;
  }

  #homeSearch .intrestLangBtn p {
    font-size: 14px !important;
  }

  #homeSearch .intrestLangBtn svg {
    width: 15px;
    height: 15px;
    margin-left: auto;
  }

  #homeSearch .searchContMob:focus-within {
    border-color: #d3d3d3 !important;
  }

  .closeButton {
    margin-top: 13px;
    margin-right: 10px;
  }

  .closeButton svg {
    width: 14px;
    height: 14px;
  }

  .homeAd {
    display: flex !important;
    margin: 20px 0px !important;
    max-width: 100% !important;
  }

  .categoryAd .adsbygoogle,
  .homeScrollAds .adsbygoogle {
    margin-left: -32px !important;
    padding-left: 16px !important;
  }
  .videoScrollAds .adsbygoogle {
    margin-left: -64px !important;
    padding-left: 32px !important;
  }
  .videoScrollAds ins {
    z-index: -1;
  }
  .homeAd > div,
  .homeAd > div > iframe {
    max-width: 100% !important;
  }
}

.chakra-modal__close-btn:focus {
  outline: 2px solid transparent !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
}
.chakra-button {
  border: none !important;
  outline: 2px solid transparent !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
}

.contest-css {
  scrollbar-width: thin;
  scrollbar-color: #bcbcbc;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #bcbcbc !important;
    border-radius: 10px;
    padding-top: 10px;
    margin-right: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d3d3d3;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #d3d3d3;
  }

  &::-webkit-scrollbar-button:vertical:start:decrement,
  &::-webkit-scrollbar-button:vertical:end:increment {
    display: none !important;
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

/* small mobile */
@media only screen and (max-width: 430px) {
  #homeSearch .preferBtnCont {
    display: block;
  }

  #homeSearch .intrestLangBtn {
    margin: 0px;
    margin-bottom: 10px;
  }
}
